import VueCookies from 'vue-cookies'
import store from '@/store'
import i18n from '@/i18n'
import { h365PlatformApi, clientLocationApi } from '@/plugin/axios'

const init = async (query, vueInstance) => {
  console.log('[Debug] route init')

  // eslint-disable-next-line no-unused-vars
  const { token: queryToken, language, hideLogo, url_scheme: queryUrlScheme, url_home: queryUrlHome } =  query

  try {
    // init language
    if (i18n.availableLocales.includes(language)) i18n.locale = language

    // init hide Logo
    if (hideLogo !== undefined) store.commit('setHideLogo', hideLogo)

    // init url scheme 返回跳轉用
    const urlScheme = queryUrlScheme ?? VueCookies.get('h365_applicationUrl')
    if (urlScheme) {
      VueCookies.set('h365_applicationUrl', urlScheme)
      store.commit('setApplicationUrl', urlScheme)
    }

    // init url home 返回跳轉用
    const urlHome = queryUrlHome ?? VueCookies.get('h365_urlHome')
    if (urlHome) {
      VueCookies.set('h365_urlHome', urlHome)
      store.commit('setUrlHome', urlHome)
    }

    // init token
    const tokenInCookie = VueCookies.get('h365')
    const tokenInQuery = queryToken // query token 優先權更大
    const token = tokenInQuery ?? tokenInCookie

    if (token) store.commit('setToken', token)

    // fetch user
    if (token) {
      try {
        const { data: { data: user } } = await h365PlatformApi.get('/user', {
          headers: {
            Authorization: `Bearer ${token}`
          }
        })
        store.commit('setUser', user)
        window?._paq?.push(['setUserId', user.userSn])
      }
      catch (e) {
        console.error('Fetch user fail', e.message)
        // 如果失敗就移除store和cookie的token
        store.commit('removeToken')
      }
    // eslint-disable-next-line brace-style
    } else {
      // eslint-disable-next-line curly
      console.log('No Token')
    }

    // fetch geolocation
    if (!store.state.clientLocation) {
      try {
        const { data } = await clientLocationApi()
        store.commit('setClientLocation', data)
      }
      catch (e) {
        // matomo trackEvent
        window?._paq?.push(['trackEvent', 'Location IP API', '取得ip異常'])
      }
    }
    // Vue.prototype.$routeInitialize = true
    vueInstance.$globalState.routeInitialize = true
    return true
  }
  catch (e) {
    console.error('init fail')
    // Vue.prototype.$routeInitialize = true
    vueInstance.$globalState.routeInitialize = true
    return false
  }
}

export default init
