<template>
  <div
    :class="{
      'h-skeleton': group === false,
      'h-skeleton-group': group === true
    }"
  >
    <template v-if="group">
      <div class="h-skeleton"></div>	
      <div class="h-skeleton"></div>	
      <div class="h-skeleton sm"></div>
    </template>
  </div>
</template>
<script>
export default {
  name: 'HSkeleton',
  props: {
    group: {
      type: Boolean,
      default: true
    }
  }
}
</script>
<style lang="scss" scoped>
.h-skeleton-group {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;


  > .h-skeleton {
    margin-bottom: 8px;

    flex: 1 100%;

    &.sm {
      max-width: 60%;
    }

    &:last-child {
      margin-bottom: 0px;
    }
  }
}
.h-skeleton {
  @include skeleton;
}
</style>