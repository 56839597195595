<template>
  <section class="h-layout" :class="{ 'h-layout--hide-logo': hideLogo }">
    <slot/>
  </section>
</template>
<script>
export default {
  name: 'HLayout',
  computed: {
    hideLogo() {
      return this.$store.state.hideLogo
    }
  }
}
</script>
<style lang="scss" scoped>
.h-layout {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  background: transparent;
  padding: 0px 16px 16px 16px;
  min-height: calc(100vh - 116px);
  justify-content: center;

  &--hide-logo {
    min-height: 100vh;
  }

  > div {
    max-width: 980px;
  }

  @include tablet {

    > div {
      max-width: unset;
    }
  }
}
</style>