import MessageComponent from '@/components/base/HMessage.vue'

const MessagePlugin = {
  install(Vue) {
    const MessageConstructor = Vue.extend(MessageComponent)
    const instance = new MessageConstructor()
    instance.$mount()

    document.body.appendChild(instance.$el)

    Vue.prototype.$message = {
      info(message) {
        instance.info(message)
      },
      success(message) {
        instance.success(message)
      },
      warning(message) {
        instance.warning(message)
      },
      error(message) {
        instance.error(message)
      }
    }
  }
}

export default MessagePlugin