<template>
  <div
    class="h-loader"
    :class="{
      'h-loader--overlay': overlay
    }"
  >
    <slot />
    <div
      class="h-loader-spinner"
      :class="{
        'h-loader-spinner--sm': size === 'sm',
        'h-loader-spinner--lg': size === 'lg',
        'h-loader-spinner--xl': size === 'xl',
        [type]: true
      }"
    >
      <div class="h-loader-spinner-dot"></div>
      <div class="h-loader-spinner-dot"></div>
      <div class="h-loader-spinner-dot"></div>
      <div class="h-loader-spinner-dot"></div>
      <div class="h-loader-spinner-dot"></div>
      <div class="h-loader-spinner-dot"></div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'HLoader',
  props: {
    size: {
      type: String,
      default: ''
    },
    overlay: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'primary'
    }
  }
}
</script>
<style lang="scss" scoped>
.h-loader {
  &--overlay {
    background-color: hsla(0, 0%, 0%, 0.6);
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 1001;
    display: flex;
    justify-content: center;
    align-items: center;

    .h-loader-spinner {
      margin: auto;
    }
  }
}
.h-loader-spinner {
  width: 40px;
  height: 40px;
  position: relative;
  animation: h-loader 2.5s infinite linear both;

  &--sm {
    width: 20px;
    height: 20px;
  }

  &--lg {
    width: 50px;
    height: 50px;
  }
  
  &--xl {
    width: 70px;
    height: 70px;
  }
}

.h-loader-spinner-dot {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0; 
  animation: h-loader-dot 2.0s infinite ease-in-out both; 
}

.h-loader-spinner {
  &.primary {
    .h-loader-spinner-dot:before {
      background-color: color(gold-4);
    }
  }
  &.error {
    .h-loader-spinner-dot:before {
      background-color: color(pink-7);
    }
  }
}

.h-loader-spinner-dot:before {
  content: '';
  display: block;
  width: 25%;
  height: 25%;
  border-radius: 100%;
  animation: h-loader-dot-before 2.0s infinite ease-in-out both; 
}

.h-loader-spinner-dot:nth-child(1) { animation-delay: -1.1s; }
.h-loader-spinner-dot:nth-child(2) { animation-delay: -1.0s; }
.h-loader-spinner-dot:nth-child(3) { animation-delay: -0.9s; }
.h-loader-spinner-dot:nth-child(4) { animation-delay: -0.8s; }
.h-loader-spinner-dot:nth-child(5) { animation-delay: -0.7s; }
.h-loader-spinner-dot:nth-child(6) { animation-delay: -0.6s; }
.h-loader-spinner-dot:nth-child(1):before { animation-delay: -1.1s; }
.h-loader-spinner-dot:nth-child(2):before { animation-delay: -1.0s; }
.h-loader-spinner-dot:nth-child(3):before { animation-delay: -0.9s; }
.h-loader-spinner-dot:nth-child(4):before { animation-delay: -0.8s; }
.h-loader-spinner-dot:nth-child(5):before { animation-delay: -0.7s; }
.h-loader-spinner-dot:nth-child(6):before { animation-delay: -0.6s; }

@keyframes  h-loader {
  100% { transform: rotate(360deg); } 
}

@keyframes h-loader-dot {
  80%, 100% { transform: rotate(360deg); } 
}

@keyframes h-loader-dot-before {
  50% {
    transform: scale(0.4); 
  } 100%, 0% {
    transform: scale(1.0); 
  } 
}

</style>