<template>
  <div class="index-page">
    <h-breadcrumb :paths="paths"/>
    <!-- 道具資訊 -->
    <h-skeleton v-if="loading" class="h-mt-30 h-mb-20"/>
    <h-product
      v-else-if="spendingId"
      :image="productDetailed.imageUrl"
      :name="productDetailed.productName"
      :spending-id="spendingId"
      :coin-pay-qty="coinPayQty + $t('coinPayment.amount')"
    />
    <div class="info">
      <div v-if="coinNeedQty > 0" class="not-enough">
        {{ `${$t('notEnoughBalance')}！` }}
      </div>
      <h-user-balance class="w-full" :coin-need-qty="coinNeedQty"/>
      <!-- 到商城充值 -->
      <template v-if="!loading">
        <div
          v-if="coinNeedQty > 0 || !spendingId"
          class="flex-col justify-center align-center w-full"
        >
          <h-button
            block
            size="large"
            shape="round"
            @click="handleGoCoinStore"
          >
            <font-icon icon="fas fa-credit-card" />
            {{ $t('coinPayment.buyMoreHcoin') }}
          </h-button>
          <div class="my-30">
            {{ $t('or') }}
          </div>
          <h-button
            block
            size="large"
            shape="round"
            to="/redeem"
          >
            <font-icon icon="fas fa-credit-card" />
            {{ $t('coinPayment.redeemCode') }}
          </h-button>
        </div>
        <h-button
          v-else
          block
          size="large"
          shape="round"
          :disabled="fetchingOrderComplete"
          @click="confirmBuy"
        >
          {{ $t('coinPayment.confirmBuy') + coinPayQty + $t('coinPayment.amount') }}
        </h-button>
      </template>
    </div>
    <h-loader v-if="fetchingOrderComplete" size="lg" overlay/>
  </div>
</template>
<script>
import HButton from '@/components/base/HButton.vue'
import HUserBalance from '@/components/HUserBalance.vue'
import HSkeleton from '@/components/base/HSkeleton.vue'
import HLoader from '@/components/base/HLoader.vue'
import HBreadcrumb from '@/components/base/HBreadcrumb.vue'
import HProduct from '@/components/payment/HProduct.vue'
import { toErrorPage } from '@/utils'
import { h365PlatformApi } from '@/plugin/axios'

export default {
  name: 'indexPage',
  components: {
    HButton,
    HUserBalance,
    HSkeleton,
    HLoader,
    HBreadcrumb,
    HProduct
  },
  data() {
    return {
      spendingId: '',
      coinPayQty: '',
      coinNeedQty: 0,
      loading: false,
      fetchingOrderComplete: false,
      showSnackBar: false,
      message: '',
      productDetailed: {
        productName: '',
        imageUrl: ''
      },
      trackPayload: {}
    }
  },
  computed: {
    paths() {
      const applicationUrl = this.$cookies.get('h365_applicationUrl') || ''
      const urlHome = this.$cookies.get('h365_urlHome') || ''
      const paths = [
        ...(applicationUrl && [{
          text: this.$t('game'),
          href: applicationUrl
        }]),
        ...(urlHome && [{
          icon: 'fa-solid fa-house',
          href: urlHome
        }]),
        {
          text: this.$t('payment.purchase'),
        }
      ]
      return paths
    }
  },
  created() {
    this.spendingId = this.$route.query.paymentId
    if (this.spendingId) this.handleCoinDetail()
  },
  methods: {
    handleGoCoinStore() {
      // matomo trackGoal 選充值方式
      window?._paq?.push(['trackGoal', 12])

      this.$router.push({ name: 'store', query: this.$route.query })
    },
    handleCoinDetail() {
      if (this.$store.state.coinDetail) {
        const coinDetail = this.$store.state.coinDetail
        this.setCoinDetail(coinDetail)
      }
      else
        this.getCoinDetailed()
    },
    async getCoinDetailed() {
      this.loading = true
      const t1 = Date.now()
      try {
        const url = '/coin/spending/' + this.spendingId
        const { data: response } = await h365PlatformApi.get(url)
        this.loading = false
        if (response.code === 200) {
          const res = response.data
          switch (res.status) {
          case 0:
            this.$store.commit('setCoinDetail', res)
            this.setCoinDetail(res)
            break
          case 2: {
            // 订单已成功
            const error = new Error(this.$t('orderCompleted'))
            error.code = 'Get coin detail failed'
            throw error
          }
          default: {
            const error = new Error(this.$t('orderStatusNotFound' + `(${res.status})`))
            error.code = 'Get coin detail failed'
            throw error
          }
          }
        }
        else {
          const error = new Error(response.message ?? this.$t('orderResponseErr'))
          error.code = 'Get coin detail failed'
          throw error
        }
      }
      catch(error) {
        // matomo trackEvent
        window?._paq?.push(['trackEvent', '消費單', '獲取明細異常', JSON.stringify({
          spendingId: this.spendingId,
          timeSpent: Date.now() - t1,
          error: error?.message ?? 'unkown error'
        })])
        toErrorPage(error?.code, error?.message)
      }
    },
    setCoinDetail(res) {
      this.productDetailed.productName = res.item.itemName
      this.productDetailed.imageUrl = res.item.imageUrl
      this.coinPayQty = res.coinPayQty
      this.coinNeedQty = res.coinNeedQty
      this.trackPayload = {
        id: this.spendingId,
        serviceId: res.serviceId,
        productId: res.item.itemId,
        productName: res.item.itemName,
        orderUsdAmount: res.orderUsdAmount
      }
    },
    handleError(errorMsg) {
      const message = this.$t(errorMsg)
      toErrorPage('Get coin detail failed', message)
    },
    async confirmBuy() {
      this.fetchingOrderComplete = true
      // matomo trackEvent
      window?._paq?.push(['trackEvent', '消費單', '確認支付', this.spendingId])

      const t1 = Date.now()
      try {
        const url = '/coin/orderComplete/' + this.spendingId
        const { data: response } = await h365PlatformApi.get(url)

        if (response.code !== 200) throw new Error(response?.message ?? 'no msg')
        
        // matomo trackEvent
        window?._paq?.push(['trackEvent', '消費單', '確認支付完成', JSON.stringify(this.trackPayload), Date.now() - t1])
        // matomo trackGoal 消費成功 TBC (Goal deleted, must create new one)
        // window?._paq?.push(['trackGoal', 3, Number(this.trackPayload.orderUsdAmount)])

        const applicationUrl = response.data.applicationUrl
        this.$router.push({
          name: 'coinSpending-detail',
          params: {
            id: this.spendingId
          },
          query: {
            ...this.$route.query,
            applicationUrl
          }
        })
      }
      catch (e) {
        this.$message.error(this.$t('coinSpendingErr'))
        // matomo trackEvent
        window?._paq?.push(['trackEvent', '消費單', '確認支付異常', JSON.stringify({
          spendingId: this.spendingId,
          timeSpent: Date.now() - t1,
          error: e?.message ?? 'unkown error'
        })])
      }
      finally {
        this.fetchingOrderComplete = false
      }
    },    
    handleImageError() {
      this.productDetailed.imageUrl = require('@/assets/image/item-default.png')
    }
  }
}
</script>
<style lang="scss" scoped>

.flex-col {
  display: flex;
  flex-direction: column;
}
.justify-center {
  justify-content: center;
}
.align-center {
  align-items: center;
}
.w-full {
  width: 100%;
}
.my-30 {
  margin: 30px 0;
}
.index-page {
  flex: 1;

  .info {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
}
.item-info {
  background-color: #ffffff;
  padding: 8px;
  border-radius: 8px;
  margin-bottom: 12px;
  display: flex;
  &-image {
    width: 80px;
    img {
       width: 100%;
    }
  }
  &-content {
    width: calc(100% - 80px);
    padding: 0px 12px;
    line-height: 20px;
    * {
      margin-bottom: 4px;
    }
    :last-child {
      margin-bottom: 0px;
    }
  }
  &-title {
    color: #262626;
    font-weight: bold;
  }
  &-coin {
    color: #595959;
    background: #FFFBE6;
    height: 20px;
    padding: 0px 8px;
    display: inline-flex;
  }
  &-serial-number {
    color: #595959;
  }
}
.info {  
  background-color: #ffffff;
  border-radius: 16px;
  padding: 32px;
}
.not-enough {
  color: color(black-2);
  font-size: 20px;
  line-height: 28px;
  margin-bottom: 32px;
  text-align: center;
  width: 100%;
}
</style>