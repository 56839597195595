<template>
  <footer class="h-footer">
    Copyright© h365 all rights reserved 2021.
  </footer>
</template>
<script>
export default {
  name: 'HFooter'
}
</script>
<style lang="scss" scoped>
.h-footer {
  background: color(gold-4);
  height: 68px;
  padding: 24px;
  font-size: 12px;
  text-align: center;
  line-height: 20px;
}
</style>