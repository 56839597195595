import Vue from 'vue'
import Vuex from 'vuex'
import { urlRedirect } from '@/utils/index'
import semverGte from 'semver/functions/gte'
import i18n from '@/i18n'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: '',
    coinDetail: null,
    discountInfo: null,
    discountList: null,
    eStoreList: null,
    hideLogo: false,
    applicationUrl: '',
    urlHome: '',
    user: null,
    csLinkMap: {
      'zh-CN': process.env.VUE_APP_LIVECAHT_CN,
      'zh-TW': process.env.VUE_APP_LIVECAHT_TW,
      'en-US': process.env.VUE_APP_LIVECAHT_EN,
    },
    clientLocation: null,
    pointcardChannelList: null,
    matomoPvId: '',
    pointCardSelect: null,
    paymentCoinPackageSelect: null
  },
  getters: {
    accountUrl(state) {
      // local
      if (process.env.NODE_ENV !== 'production')
        return process.env.VUE_APP_LOCAL_ACCOUNT_SERVICE_URL ?? 'https://ssaccount.h365.games'
      // sandbox
      if (process.env.VUE_APP_DEPLOY_ENV === 'sandbox')
        return 'https://ssaccount.h365.games'
      // production

      // pp2 or account origin url
      const urlHome = state?.urlHome || ''

      const extractRootDomain = (url) => {
        const parsedUrl = new URL(url)
        const rootDomain = parsedUrl.hostname.split('.').slice(-2).join('.')
        return rootDomain
      }

      // account flow
      if (urlHome.includes('account'))
        return urlHome
      // player-protal & webgame flow
      else if (urlHome) {
        const rootDomain = extractRootDomain(urlHome)
        return `https://account.${rootDomain}`
      }
      // sdk flow
      else {
        // host: payment domain
        const host = window.location.origin
        const rootDomain = extractRootDomain(host)
        return `https://account.${rootDomain}`
      }
    },
    emailVerified(state) {
      return state?.user?.emailVerified || false
    },
    customerServiceLink(state) {
      let link = state.csLinkMap[i18n.locale] ?? ''
      if (!link) return ''
      try {
        const sdkVersion = new URLSearchParams(location.search).get('sdkVersion')
        const prefix = (sdkVersion && semverGte(sdkVersion, '1.2.0')) ? 'browser:' : ''
        return prefix + link
      }
      catch (e) {
        console.error('Resolve customer service link error', e.message)
        return link
      }
    },
    myAccountLink(state, getters) {
      if (!getters.accountUrl) return ''
      const urlObj = new URL('/account', getters.accountUrl)
      urlObj.searchParams.set('token', state.token)
      urlObj.searchParams.set('language', i18n.locale)
      try {
        const sdkVersion = new URLSearchParams(location.search).get('sdkVersion')
        const prefix = (sdkVersion && semverGte(sdkVersion, '1.2.0')) ? 'browser:' : ''
        return `${prefix}${urlObj.toString()}`
      }
      catch (e) {
        console.log('Resolve my Order Detail link error', e.message)
        return urlObj.toString()
      }
    },
    myOrderLink(state, getters) {
      if (!getters.accountUrl) return ''
      const urlObj = new URL('/my-games', getters.accountUrl)
      urlObj.searchParams.set('token', state.token)
      urlObj.searchParams.set('language', i18n.locale)
      try {
        const sdkVersion = new URLSearchParams(location.search).get('sdkVersion')
        const prefix = (sdkVersion && semverGte(sdkVersion, '1.2.0')) ? 'browser:' : ''
        return `${prefix}${urlObj.toString()}`
      }
      catch (e) {
        console.log('Resolve my Order Detail link error', e.message)
        return urlObj.toString()
      }
    }
  },
  mutations: {
    setUser(state, data) {
      state.user = data
    },
    setToken(state, token) {
      state.token = token
      this._vm.$cookies.set('h365', token, { sameSite: 'lax' })
    },
    removeToken(state) {
      state.token = ''
      this._vm.$cookies.remove('h365')
    },
    setCoinDetail(state, coinDetail) {
      state.coinDetail = coinDetail
    },
    setHidePlatformInfo(state, serviceId) {
      if (
        process.env.VUE_APP_HIDE_PLATFORM_INFO_SERVICE_ID &&
        serviceId &&
        process.env.VUE_APP_HIDE_PLATFORM_INFO_SERVICE_ID.includes(serviceId)
      ) this._vm.$cookies.set('hidePlatformInfo', true, { sameSite: 'lax' })
      state.hidePlatformInfo = this._vm.$cookies.get('hidePlatformInfo') || false
    },
    setDiscountInfo(state, data) {
      state.discountInfo = data
    },
    setDiscountList(state, data) {
      state.discountList = data
    },
    setEStoreList(state, data) {
      state.eStoreList = data
    },
    setHideLogo(state, data) {
      state.hideLogo = JSON.parse(data) === true
    },
    setApplicationUrl(state, data) {
      state.applicationUrl = data
    },
    setUrlHome(state, data) {
      state.urlHome = data
    },
    setClientLocation(state, data) {
      state.clientLocation = data
    },
    setPointcardChannelList(state, data) {
      state.pointcardChannelList = data
    },
    setMatomoPvId(state) {
      window?._paq?.push(['setCustomRequestProcessing', function(params) {
        const urlString = decodeURIComponent(params)
        const urlParams = new URLSearchParams(urlString)
        for (const param of urlParams) {
          const [name, val] = param
          if (name === 'pv_id') state.matomoPvId = val
        }
        return params
      }])
    },
    setPointCardSelect(state, data) {
      state.pointCardSelect = data
      this._vm.$cookies.set('h365_pointcard', JSON.stringify(data), { sameSite: 'lax' })
    },
    setPaymentCoinPackageSelect(state, data) {
      state.paymentCoinPackageSelect = data
      this._vm.$cookies.set('h365_paymentCoinPackage', JSON.stringify(data), { sameSite: 'lax' })
    }
  },
  actions: {
    handleVerifyEmail({ state, getters }) {
      const token = state.token
      const accountUrl = new URL(getters.accountUrl)
      const url = `${accountUrl.origin}/verify-email`
      const language = i18n.locale
      try {
        const sdkVersion = this._vm.$route?.query?.sdkVersion || ''
        if (sdkVersion && semverGte(sdkVersion, '1.2.0'))
          urlRedirect(`browser:${url}`, { token, language }, '_blank')
        else
          urlRedirect(url, { token, language }, '_blank')
      }
      catch(e) {
        console.error(e)
        urlRedirect(url, { token, language }, '_blank')
      }
    }
  },
  modules: {
  }
})
