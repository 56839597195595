import Vue from 'vue'
import i18n from '@/i18n'

import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode
} from 'vee-validate'
import { required, email, confirmed, numeric } from 'vee-validate/dist/rules'

Vue.component('ValidationObserver', ValidationObserver)
Vue.component('ValidationProvider', ValidationProvider)


setInteractionMode('eager')

extend('required', {
  ...required,
  message: (fieldName) => {
    return i18n.t('validations.required', {
      _field_: fieldName
    })
  }
})

extend('numeric', {
  ...numeric,
  message: (fieldName) => {
    return i18n.t('validations.numeric', {
      _field_: fieldName
    })
  }
})

extend('email', {
  ...email,
  validate(value) {
    const regex = /^\w+((-\w+)|(\.\w+))*@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z]+$/
    return regex.test(value)
  },
  message: (fieldName) => {
    return i18n.t('validations.email', {
      _field_: fieldName
    })
  }
})

// Support email that including special characters to login
extend('email2', {
  ...email,
  message: (fieldName) => {
    return i18n.t('validations.email', {
      _field_: fieldName
    })
  }
})

extend('check', {
  validate(value) {
    return value === true
  },
  message: (fieldName) => {
    return i18n.t('validations.check', {
      _field_: fieldName
    })
  }
})

extend('betweenLength', {
  validate(value, { min, max }) {
    return value.length >= min && value.length <= max
  },
  params: ['min', 'max'],
  message: (fieldName, placeholders) => {
    return i18n.t('validations.between', {
      _field_: fieldName,
      ...placeholders
    })
  }
})

extend('confirmed', {
  ...confirmed,
  message: (fieldName) => {
    return i18n.t('validations.confirmed', {
      _field_: fieldName
    })
  }
})