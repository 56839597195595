/**
 * route meta title 會影響 matomo 蒐集頁面資訊名稱 trackPageView(title)
 * 可參考vue-matomo源碼 https://github.com/AmazingDreams/vue-matomo/blob/master/src/index.js#L44 (trackMatomoPageView函式)
 */
export default [
  {
    path: '/v2',
    name: 'index-v2',
    meta: {
      title: '首頁V2',
      auth: true
    },
    component: () => import('@/views/v2/index.vue')
  },
  {
    path: '/v2/payment/order',
    name: 'payment-create-order-v2',
    meta: {
      title: '支付-選擇支付通道V2',
      auth: true
    },
    component: () => import('@/views/v2/paymentOrder.vue')
  },
  {
    path: '/v2/payment/detail/:id',
    name: 'payment-detail-v2',
    meta: {
      title: '支付-支付明細V2',
      auth: true
    },
    component: () => import('@/views/v2/paymentDetail.vue')
  },
  {
    path: '/v2/redeem/channel/:id',
    name: 'redeem-serial-number-v2',
    meta: {
      title: '點數兌換-輸入序號V2',
      auth: true
    },
    component: () => import('@/views/v2/redeem.vue')
  }
]
