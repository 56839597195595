import Vue from 'vue'
import VueRouter from 'vue-router'
import IndexPage from '../views/index.vue'
import paymentRoutes from './payment'
import coinSpendingRoutes from './coinSpending'
import v2Routes from './v2'
import store from '@/store'
import init from './init'
import { ERROR_CODE } from '@/constant'

Vue.use(VueRouter)

/**
 * route meta title 會影響 matomo 蒐集頁面資訊名稱 trackPageView(title)
 * 可參考vue-matomo源碼 https://github.com/AmazingDreams/vue-matomo/blob/master/src/index.js#L44 (trackMatomoPageView函式)
 */
const routes = [
  {
    path: '/',
    name: 'index',
    meta: {
      title: '首頁',
      auth: true
    },
    component: IndexPage,
    beforeEnter: async (to, from, next) => {
      console.log('before enter index page route')
      // 当进入Variation1, app.vue以下代码未执行到，导致Variation1发出的pageview和event tracking都当作是新的visit
      // this.$router.onReady(() => {
      //   window?._paq?.push(['appendToTrackingUrl', ''])
      // })
      // 这里做了异步执行，保证第一次发出的event是有new_visit=1,后面的event是不会带上new_visit=1
      setTimeout(() => {
        // matomo vue-matomo pre-init時有處理new_visit，為了使其他tracking不被當做new_visit
        window?._paq?.push(['appendToTrackingUrl', ''])
      }, 0)
      if (process.env.VUE_APP_MATOMO_ENABLED !== '1') next({ name: 'index-v2', query: { ...to.query } })
      window?._paq?.push(['AbTesting::create', {
        name: process.env.VUE_APP_MATOMO_ABTEST_NAME ?? 'TopupFlowBvsC',
        percentage: process.env.VUE_APP_MATOMO_PERCENTAGE_OF_VISITORS ? Number(process.env.VUE_APP_MATOMO_PERCENTAGE_OF_VISITORS) : 100,
        includedTargets: [{"attribute":"url","inverted":"0","type":"any","value":""}],
        excludedTargets: [],
        variations: [{
          name: 'original',
          percentage: 50,
          activate: function() {
            // usually nothing needs to be done here
            next()
          }
        },
        {
          name: 'Variation1',
          percentage: 50,
          activate: function() {
            next({ name: 'index-v2', query: { ...to.query } })
          }
        }],
        trigger: function() {
          return true // here you can further customize which of your visitors will participate in this experiment
        }
      }])
      next()
    }
  },
  {
    path: '/store',
    name: 'store',
    meta: {
      title: '充值包-選擇充值包',
      auth: true
    },
    component: () => import('@/views/store.vue')
  },
  {
    path: '/redeem',
    name: 'redeem-index',
    meta: {
      title: '點數兌換-選擇點數商',
      auth: true
    },
    component: () => import('@/views/redeem/index.vue')
  },
  {
    path: '/redeem/channel/:id',
    name: 'redeem-serial-number',
    meta: {
      title: '點數兌換-輸入序號',
      auth: true
    },
    component: () => import('@/views/redeem/detail.vue')
  },
  {
    path: '/redeem/sugoi-result',
    name: 'redeem-sugoi-result',
    meta: {
      title: '點數兌換-Sugoi結果頁',
    },
    component: () => import('@/views/redeem/sugoiResult.vue')
  },
  {
    path: '/redeem/mtpay-result',
    name: 'redeem-mtpay-result',
    meta: {
      title: '點數兌換-APPA結果頁',
    },
    component: () => import('@/views/redeem/mtpayResult.vue')
  },
  {
    path: '/redeem/inProgress',
    name: 'redeem-inprogress',
    meta: {
      title: '點數兌換-兌換中等待頁',
      auth: true
    },
    component: () => import('@/views/redeem/loading.vue')
  },
  ...paymentRoutes,
  ...coinSpendingRoutes,
  ...v2Routes,
  {
    path: '/error',
    name: 'error',
    meta: {
      title: '錯誤頁',
    },
    component: () => import('@/views/layout/error.vue')
  },
  {
    path: "*",
    name: 'page-not-found',
    meta: {
      title: '無對應的路由',
    },
    component: () => import('@/views/layout/pageNotFound.vue')
  },
  {
    path: "/404",
    name: '404',
    meta: {
      title: '404錯誤',
    },
    component: () => import('@/views/layout/pageNotFound.vue')
  },
  {
    path: "/401",
    name: '401',
    meta: {
      title: '401錯誤',
    },
    component: () => import('@/views/layout/unauthorized.vue')
  },
  {
    path: "/500",
    name: '500',
    meta: {
      title: '500錯誤',
    },
    component: () => import('@/views/layout/exception.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  /**
   *
   * @returns https://router.vuejs.org/zh/guide/advanced/scroll-behavior.html
   * @param to
   * @param from
   * @param savedPosition
   */
  scrollBehavior: () => {
    return { x: 0, y: 0 } // 始终滚动到顶部
  },
  routes,
})

router.afterEach(() => {
  console.log('[Debug] after each')
})

router.beforeEach(async (to, from, next) => {
  console.log('[Debug] before each')
  if (to?.path === '/v2/payment/order' || to?.path === '/payment/createdOrder') store.commit('setMatomoPvId')
  // fix ios old version sdk invalid token issue
  if (to?.query?.token && to.query.token.indexOf('?') !== -1) {
    const { token: _token, ...otherQuery } = to.query
    const replaceQuery = 'token=' + String(_token).replaceAll('?', '&')

    // @todo matomo trackEvent url_replace_token_issue
    // console.log(to)
    window?._paq?.push(['trackEvent', 'Accident', 'Router beforeEach', 'Invalid Token in URL Query'])

    return next({
      path: from.fullPath + '?' + replaceQuery,
      replace: true,
      query: otherQuery
    })
  }

  // if init fail will next (navgation) to 500 error page
  if (router.app.$globalState.routeInitialize === false) {
    const initResult = await init(to.query, router.app)
    if (!initResult) {
      // console.log('init fail')
      return next({ name: '500', query: { code: ERROR_CODE.INIT_FAIL, ...to.query }})
    }
  }

  const requireAuth = to.matched.some(record => record.meta.auth)
  if (requireAuth) {
    const token = store.state.token
    const isAuthenticated = !!(token)

    return (isAuthenticated) ? next() : next({ name: '401', query: { ...to.query } })
  }
  else {
    // console.log('before each next()')
    return next()
  }

})

export default router
