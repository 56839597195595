/**
 * route meta title 會影響 matomo 蒐集頁面資訊名稱 trackPageView(title)
 * 可參考vue-matomo源碼 https://github.com/AmazingDreams/vue-matomo/blob/master/src/index.js#L44 (trackMatomoPageView函式)
 */
export default [
  {
    path: '/coinSpending',
    name: 'coinSpending',
    component: () => import('@/views/coinSpending'),
    children: [
      {
        path: 'detail/:id',
        name: 'coinSpending-detail',
        meta: {
          title: '消費-消費明細'
        },
        component: () => import('@/views/coinSpending/detail')
      }
    ]
  }
]