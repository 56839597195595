import router from '@/router'

export const setDiscountTable = (data) => {
  if (!data) return
  const deepCopy = data => JSON.parse(JSON.stringify(data))
  // header
  const headerSet = [...new Set(data.map((item) => {
    if (parseFloat(item.discount) > 0)
      item.isDiscount = true
    else item.isDiscount = false
    if (parseFloat(item.freeCoinAmt) > 0)
      item.isFreeCoinAmt = true
    else item.isFreeCoinAmt = false
    return JSON.stringify({
      customId: `${item.payMethodId}_${item.name}`,
      payMethodId: item.payMethodId,
      name: item.name,
      isDiscount: item.isDiscount,
      isFreeCoinAmt: item.isFreeCoinAmt,
      iconUrl: item.iconUrl
    })
  }))]
  const header = headerSet.map(item => JSON.parse(item))
  // Columns
  const initColumns = deepCopy(header)
    .map((item) => {
      item.discount = 0
      item.freeCoinAmt = 0
      return item
    })
  const insertData = (col, current) => {
    const index = col
      .findIndex(item => item.customId === `${current.payMethodId}_${current.name}`)
    col[index].discount = current.discount
    col[index].freeCoinAmt = current.freeCoinAmt
  }
  // Rows
  const result = data.reduce((acc, cur) => {
    const columns = deepCopy(initColumns)
    if (!acc[cur.maxCoinAmt]) {
      insertData(columns, cur)
      acc[cur.maxCoinAmt] = [cur.maxCoinAmt, columns]
    }
    else insertData(acc[cur.maxCoinAmt][1], cur)
    return acc
  }, {})
  // convert to array
  const rows = Object.entries(result).map(item => item[1])
  return { header, rows }
}

export const toErrorPage = (status = '', message = '') => {
  localStorage.setItem('h365-error', JSON.stringify({ status, message }))
  if (router.currentRoute.path  !== '/error')
    router.push('/error')
}

export const getLocalImg = (name) => {
  const imageName = name.replace('.png', '')
  const images = require.context('@/assets/image/payment/', false, /\.png$/)
  return images('./' + imageName + '.png')
}

export const getCoinImageUrl = (name) => {
  const imageName = name ? name.replace('.png', '') : 'sun'
  const images = require.context('@/assets/image/topup/', false, /\.png$/)
  return images('./' + imageName + '.png')
}

export const getDiscountImg = (name) => {
  if (!name) return ''
  try {
    const imageName = name.replace('.png', '')
    const images = require.context('@/assets/image/discount/', false, /\.png$/)
    return images('./' + imageName + '.png')
  }
  catch {
    return ''
  }
}

function getUrlAppendParams(url, data) {
  const decodeUrl = new URL(decodeURIComponent(url))
  for (const key in data)
    decodeUrl.searchParams.set(key, data[key])
  return decodeUrl.toString()
}

export const urlRedirect = (url, params, target = null) => {
  if (!url) return
  const a = document.createElement('a')
  if (params) url = getUrlAppendParams(url, params)
  a.href = url
  if (target) a.target = target
  document.body.appendChild(a)
  a.click()
}