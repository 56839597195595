<template>
  <div class="h-product">
    <div class="title">
      {{ $t('product.title') }}
    </div>
    <hr class="divider"/>
    <div class="h-product-image" :class="{ loading: (imageSrc && imageLoading) }">
      <img :src="imageSrc" @load="imageSuccessLoad" @error="handleImageError">
    </div>
    <div class="h-product-content">
      <div class="h-product-title">{{ name }}</div>
      <div class="h-product-coin">{{ coinPayQty }}</div>
      <div class="h-product-serial-number">
        <div class="h-product-serial-number-title">
          {{ `${$t('payment.orderId')}：` }}
        </div>
        <div class="h-product-serial-number-content">
          {{ spendingId }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'HProduct',
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    spendingId: {
      type: String,
      default: ''
    },
    image: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    coinPayQty: {
      type: [String, Number],
      default: ''
    }
  },
  data() {
    return {
      imageLoading: true,
      imageSrc: '',
      defaultImage: require('@/assets/image/item-default.png')
    }
  },
  mounted() {
    this.imageSrc = this.image || this.defaultImage
  },
  methods: {
    handleImageError(e) {
      e.target.src = require('@/assets/image/item-default.png')
    },
    imageSuccessLoad() {
      this.imageLoading = false
    }
  }
}
</script>
<style lang="scss" scoped>
.h-product {
  background-color: #ffffff;
  padding: 16px;
  border-radius: 8px;
  margin-bottom: 12px;
  display: flex;
  flex-wrap: wrap;

  .divider {
    height: 1px;
    width: 100%;
    background-color: #262626;
    border: none;
  }

  .title {
    font-size: 16px;
    width: 100%;
    margin-bottom: 0px;
  }

  &-image {
    width: 80px;

    &.loading {
      @include skeleton;
      height: 80px;
    }

    img {
      width: 100%;
      // background-image: url('@/assets/image/item-default.png');
    }
  }
  &-content {
    width: calc(100% - 80px);
    padding: 0px 12px;
    line-height: 20px;
    * {
      margin-bottom: 4px;
    }
    :last-child {
      margin-bottom: 0px;
    }
  }
  &-title {
    color: #262626;
    font-weight: bold;
    letter-spacing: 0.12em;
  }
  &-coin {
    color: #595959;
    font-weight: bold;
    letter-spacing: 0.06em;
    display: inline-flex;
  }
  &-serial-number {
    letter-spacing: 0.06em;
    font-size: 14px;
    color: #595959;
  }
}
</style>