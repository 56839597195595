var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"h-loader",class:{
    'h-loader--overlay': _vm.overlay
  }},[_vm._t("default"),_c('div',{staticClass:"h-loader-spinner",class:( _obj = {
      'h-loader-spinner--sm': _vm.size === 'sm',
      'h-loader-spinner--lg': _vm.size === 'lg',
      'h-loader-spinner--xl': _vm.size === 'xl'
    }, _obj[_vm.type] = true, _obj )},[_c('div',{staticClass:"h-loader-spinner-dot"}),_c('div',{staticClass:"h-loader-spinner-dot"}),_c('div',{staticClass:"h-loader-spinner-dot"}),_c('div',{staticClass:"h-loader-spinner-dot"}),_c('div',{staticClass:"h-loader-spinner-dot"}),_c('div',{staticClass:"h-loader-spinner-dot"})])],2)}
var staticRenderFns = []

export { render, staticRenderFns }