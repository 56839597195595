<template>
  <div id="app">
    <h-header v-if="!hideLogo" />
    <h-error-boundary scope="router-view">
      <h-layout>
        <router-view v-if="$globalState.routeInitialize" />
        <div v-else class="sppiner-container">
          <span class="loader" />
        </div>
      </h-layout>
    </h-error-boundary>
    <h-footer v-if="!hideLogo" />
  </div>
</template>
<script>
import HHeader from '@/components/layout/HHeader.vue'
import HLayout from '@/components/layout/HLayout.vue'
import HFooter from '@/components/layout/HFooter.vue'
import HErrorBoundary from '@/components/base/HErrorBoundary.vue'

export default {
  name: 'AppRoot',
  components: {
    HHeader,
    HLayout,
    HFooter,
    HErrorBoundary
  },
  computed: {
    hideLogo() {
      return this.$store.state.hideLogo
    }
  },
  created() {
    this.$router.onReady(() => {
      // matomo vue-matomo pre-init時有處理new_visit，為了使其他tracking不被當做new_visit
      window?._paq?.push(['appendToTrackingUrl', ''])
    })
  }
}
</script>
