<template>
  <div>
    <!--如果發生錯誤邊界不想渲染自己加v-if/else-->
    <!-- <div v-if="error">This is Sample Something wrong....</div>
    <slot v-else/> -->
    <slot />
  </div>
</template>
<script>
export default {  
  name: 'HErrorBoundary',
  props: {
    scope: {
      type: String,
      default: 'none'
    }
  },
  data: () => ({
    error: false,
    msg: ''
  }),
  errorCaptured(err, vm, info) {
    if (window.localStorage.getItem('errorBoundary')) {
      console.log('======錯誤邊界捕捉======')
      console.log(`scope:${this.scope}`)
      console.log(`info:${info}`)
      console.log(err)
      console.log(vm)
      console.log('======================')
      this.$message.error('頁面發生異常')
    }
    this.msg = err?.message ?? 'no msg'
    // @todo matomo trackEvent
    window?._paq?.push(['trackEvent', 'ErrorBoundary', this.scope, this.msg])
    this.error = true
  }
}
</script>