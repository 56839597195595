<template>
  <transition name="message-transition">
    <div v-if="message" class="h-message">
      <div class="h-message-conatiner">
        <div class="h-message-icon" :class="iconClass">
          <font-icon
            v-if="type === 'info' || type === 'warning'"
            :icon="['fas', 'info-circle']"
          />
          <font-icon
            v-if="type === 'error'"
            :icon="['fas', 'circle-xmark']"
          />
          <font-icon
            v-if="type === 'success'"
            :icon="['fas', 'circle-check']"
          />
        </div>
        <div class="h-message-content">
          {{ message }}
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
export default {
  name: 'HMessage',
  data() {
    return {
      type: 'info',
      message: ''
    }
  },
  computed: {
    iconClass() {
      if (['info', 'success', 'warning', 'error'].includes(this.type))
        return `h-message--${this.type}`
      return 'h-message--info'
    }
  },
  methods: {
    info(message) {
      this.addMessage(message, 'info')
    },
    success(message) {
      this.addMessage(message, 'success')
    },
    warning(message) {
      this.addMessage(message, 'warning')
    },
    error(message) {
      this.addMessage(message, 'error')
    },
    addMessage(content, type) {
      this.message = content
      this.type = type
      setTimeout(() => {
        this.removeMessage()
      }, 3000)
    },
    removeMessage() {
      this.message = ''
      this.type = 'info'
    }
  }
}
</script>
<style lang="scss" scoped>
.h-message {
  position: fixed;
  top: 20px;
  left: 0;
  right: 0;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;

  &-conatiner {
    border-radius: 8px;
    background-color: #ffffff;
    display: flex;
    padding: 8px;
    box-shadow: 2px 2px 5px #b0b0b0;
  }

  &-content {
    padding-left: 8px;
  }

  &--info {
    color: #1890ff;
  }

  &--success {
    color: #52c41a;
  }

  &--warning {
    color: #faad14;
  }

  &--error {
    color: #ff4d4f;
  }
}

.message-transition-enter-active {
  transition: transform 0.3s, opacity 0.3s;
}

.message-transition-enter {
  transform: translateY(-100%);
  opacity: 0;
}

.message-transition-leave-active {
  transition: transform 0.3s, opacity 0.3s;
}

.message-transition-leave-to {
  transform: translateY(0);
  opacity: 0;
}
</style>