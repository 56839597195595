<template>
  <div class="user-balance">
    <!-- 你的餘額 -->
    <div class="user-balance-item">
      <div class="user-balance-item-title">
        {{ `${$t('coinPayment.balance')}：` }}
      </div>
      <div class="user-balance-item-value">
        <h-loader v-if="!userInfo" />
        <template v-else>
          {{ userBalance }}
        </template>
        <img src="@/assets/image/coin/coin_icon.svg" alt="H Coin">
        {{ $t('coinPayment.coin') }}
      </div>
    </div>
    <!-- 你還需要 -->
    <div v-if="coinNeedQty > 0" class="user-balance-item">
      <div class="user-balance-item-title">
        {{ `${$t('coinPayment.needCoin')}：` }}
      </div>
      <div class="user-balance-item-value">
        <span class="txt-color--failed">{{ `+ ${coinNeedQty}` }}</span>
        <img src="@/assets/image/coin/coin_icon.svg" alt="H Coin">
        {{ $t('coinPayment.coin') }}
      </div>
    </div>
    <div v-if="exchangeRate" class="exchange-rate text-xs">
      {{ $t('coinPayment.cnyExchangeCoin') }}
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import HLoader from '@/components/base/HLoader.vue'

export default {
  name: 'HUserBalance',
  components: { HLoader },
  props: {
    coinNeedQty: {
      type: Number,
      default: 0
    },
    exchangeRate: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      balance: '-'
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.user
    }),
    userBalance() {
      return  Number(this.userInfo?.paidCoinAmt ?? 0) + Number(this.userInfo?.freeCoinAmt ?? 0)
    }
  }
}
</script>
<style lang="scss" scoped>
.user-balance {
  padding: 16px;

  &-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    line-height: 24px;
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0px;
    }
  }
  &-item-value {
    display: flex;
    align-items: center;
  }
  &-item-value img {
    height: 24px;
    margin: 0px 4px;
  }
  .exchange-rate {
    color: #8c8c8c;
    text-align: right;
  }
}
</style>
