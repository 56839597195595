import Vue from 'vue'
import dayjs from 'dayjs'


Vue.filter('formatDate', (val, formatString) => {
  try {
    return isNaN(Number(val))
      ? ''
      : dayjs(Number(val)).format(formatString)
  }
  catch (e) {
    console.warn('dayjs format error', e.message)
    return ''
  }
})